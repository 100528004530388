import * as yup from 'yup';

export const schema = yup.object().shape({
  name: yup.string().required('You must enter a name').trim().max(100, 'Name is too long'),
  email: yup
    .string()
    .email('Invalid email provided')
    .required('You must enter an email')
    .trim()
    .max(100, 'Email is too long'),
  subject: yup.string().required('You must enter a subject').trim().max(100, 'Subject is too long'),
  recaptchaToken: yup.string().required('You must complete the captcha'),
  message: yup
    .string()
    .min(1, 'Message must be at least 1 character length')
    .max(500, 'Reached the limit of a message')
    .trim(),
    policyAccepted: yup.bool().oneOf([true], 'You must accept our privacy policy'),
    signUpForNewsletter: yup.bool(),
});
