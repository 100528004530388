import styled from '@emotion/styled';
import { Modal } from 'react-bootstrap';
export const Header = styled(Modal.Header)`
  border: 0;
  filter: invert(1);
  width: 100%;
  justify-content: flex-end;
  padding-inline: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

  .contents {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 480px;
    margin: auto;
    padding: 10px 20px;
  }
`;
