import styled from '@emotion/styled';
import variables from '../styles/variables';

export const Section = styled.section`
  h1 {
    margin-bottom: 100px;
  }

  .contacts {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 500px) {
      flex-direction: column;
    }
  }
  .contact {
    border-left: 2px solid ${variables.purpleColor};
    padding: 5px 38px 20px 15px;
    flex: 1;

    .title {
      font-size: 25px;
      margin-bottom: 40px;
    }
    p {
      font-size: 20px;
      line-height: 28px;
      font-weight: 300;
    }
  }
`;
