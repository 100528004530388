import { Nav, Offcanvas } from 'react-bootstrap';
import { LINKS } from './links';
import * as Styles from './styles';

export default function MobileNavbar({ onMobileMenuClose, opened }) {
  return (
    <Offcanvas show={opened} onHide={onMobileMenuClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title></Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="d-flex flex-column">
        <Nav>
          {Object.values(LINKS).map(({ label, href }) => (
            <Styles.MobileNavLink className="text-dark my-2" key={href} href={href} onClick={onMobileMenuClose}>
              {label}
            </Styles.MobileNavLink>
          ))}
        </Nav>
        <div className="mt-auto">
          <a href={process.env.REACT_APP_REQUEST_DEMO_URL} rel="noopener noreferrer" className="action-button">
            Become an early access developer
          </a>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
