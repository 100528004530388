import styled from '@emotion/styled';
import { Container as BsContainer, Navbar as BsNavbar, Nav as BsNav } from 'react-bootstrap';
import variables from '../../styles/variables';

export const Logo = styled.img`
  width: 100%;
  max-width: 200px;

  @media (max-width: ${variables.smallBreakPoint}) {
    max-width: 150px;
  }
`;

export const Container = styled(BsContainer)`
  max-width: 1200px;
`;

export const Navbar = styled(BsNavbar)`
  padding-block: 20px;
  padding-inline: 20px;
  transition: padding-block 0.5s ease-in-out;

  &.shrink {
    padding-block: 10px;
    background-color: #000000;
  }
`;

export const MobileNavLink = styled(BsNav.Link)`
  font-size: 20px;
`;
