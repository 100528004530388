import styled from '@emotion/styled';
import variables from '../styles/variables';

export const Section = styled.section`
  h1 {
    margin-bottom: 60px;
  }
  h2 {
    margin-bottom: 66px;
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
    span {
      color: ${variables.purpleColor};
    }
  }
  p {
    max-width: 980px;
    margin: auto;
    text-align: center;
    margin-bottom: 28px;
  }
`;
