import { useState } from 'react';
import { ImgContainer } from './style';

const VIDEO_EXTENSIONS = ['mp4'];

/**
 *
 * @param {{
 *  src: string;
 *  loadingSrc: string;
 *  alt?: string;
 * }} param0
 * @returns {JSX.Element}
 */
export default function LazyMedia({ src, loadingSrc, alt }) {
  const [loaded, setLoaded] = useState(false);
  const extension = src.split('.').pop();
  const isVideo = VIDEO_EXTENSIONS.includes(extension.toLowerCase());

  const handleLoaded = () => {
    setLoaded(true);
  };

  return (
    <ImgContainer isLoaded={loaded} loadingBackground={loadingSrc}>
      {isVideo ? (
        <video autoPlay muted controls={false} loop onLoadedData={handleLoaded} type={`video/${extension}`}>
          <source src={src} />
        </video>
      ) : (
        <img src={src} loading="lazy" alt={alt} onLoad={handleLoaded} />
      )}
    </ImgContainer>
  );
}
