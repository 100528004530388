import { DoubleLayerButton } from '../components';
import SKGamingImage from '../assets/images/btn_collaborator_1.png';
import HubRaumImage from '../assets/images/btn_collaborator_2.png';
import WayraImage from '../assets/images/btn_collaborator_3.png';
import AwsImage from '../assets/images/btn_collaborator_4.png';
import VerizonImage from '../assets/images/btn_collaborator_5.png';
import FilmUndMedienNRW from '../assets/images/btn_collaborator_6.png';
import Vodafone from '../assets/images/btn_collaborator_7.png';
import Rogers from '../assets/images/btn_collaborator_8.png';
import SKTelecom from '../assets/images/btn_collaborator_9.png';
import SingtelImage from '../assets/images/btn_collaborator_10.png';
import * as S from './partners.styles';
import classNames from 'classnames';

export default function Partners({ className }) {
  return (
    <S.Section className={classNames('container', className)} id="partners">
      <h1>OUR COLLABORATORS</h1>
      <div className="d-flex flex-wrap button-list ">
        <DoubleLayerButton className="collaborator-btn">
          <img src={VerizonImage} alt={'collaborator-btn'} height="auto" />
        </DoubleLayerButton>
        <DoubleLayerButton className="collaborator-btn">
          <img src={SKTelecom} alt={'collaborator-btn'} height="auto" />
        </DoubleLayerButton>
        <DoubleLayerButton className="collaborator-btn">
          <img src={SingtelImage} alt={'collaborator-btn'} height="auto" />
        </DoubleLayerButton>
        <DoubleLayerButton className="collaborator-btn">
          <img src={AwsImage} alt={'collaborator-btn'} height="auto" />
        </DoubleLayerButton>
        <DoubleLayerButton className="collaborator-btn">
          <img src={Rogers} alt={'collaborator-btn'} height="auto" />
        </DoubleLayerButton>
        <DoubleLayerButton className="collaborator-btn">
          <img src={Vodafone} alt={'collaborator-btn'} height="auto" />
        </DoubleLayerButton>
        <DoubleLayerButton className="collaborator-btn">
          <img src={SKGamingImage} alt={'collaborator-btn'} height="auto" />
        </DoubleLayerButton>
        <DoubleLayerButton className="collaborator-btn">
          <img src={FilmUndMedienNRW} alt={'collaborator-btn'} height="auto" />
        </DoubleLayerButton>
        <DoubleLayerButton className="collaborator-btn">
          <img src={HubRaumImage} alt={'collaborator-btn'} height="auto" />
        </DoubleLayerButton>
        <DoubleLayerButton className="collaborator-btn">
          <img src={WayraImage} alt={'collaborator-btn'} height="auto" />
        </DoubleLayerButton>
      </div>
    </S.Section>
  );
}
