import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import SuccessScreen from '../SuccessScreen';
import ContactForm from './ContactForm';
import * as S from './styles';

export default function ContactFormModal({ onClose }) {
  const [isSuccess, setIsSuccess] = useState(false);

  function handleOnSuccess(success) {
    setIsSuccess(success);
  }

  return (
    <Modal onHide={onClose} show fullscreen contentClassName="contact-modal-content">
      <S.Header closeButton />
      <Modal.Body>
        <S.Content>
          {isSuccess ? (
            <SuccessScreen title="THANK YOU FOR EMAILING US!" content="We will reach to you shortly." />
          ) : (
            <ContactForm onSuccess={handleOnSuccess} />
          )}
        </S.Content>
      </Modal.Body>
    </Modal>
  );
}
