/**
 * @param {Object} params
 * @param {string} params.name
 * @param {string} params.email
 * @param {string} params.subject
 * @param {string} params.message
 * @param {string} params.origin
 * @param {string} params.recaptchaToken
 * @param {boolean} params.policyAccepted
 * @param {boolean} params.signUpForNewsletter
 */
export function sendEmail(params) {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_SYNCSTAGE_API}/email-us`, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.ok) {
          resolve();
        } else {
          reject();
        }
      })
      .catch(() => {
        reject();
      });
  });
}
