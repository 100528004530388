import styled from '@emotion/styled';
import variables from '../styles/variables';

export const Section = styled.section`
  position: relative;

  h1 {
    margin-bottom: 55px;
  }

  .layouts {
    @media (max-width: ${variables.largeBreakPoint}) {
      flex-direction: column;
      gap: 40px;
    }
  }

  .contents-side {
    width: 40%;
    @media (max-width: ${variables.largeBreakPoint}) {
      width: 100%;
    }

    .logo {
      width: 360px;
      height: 108px;
      margin-bottom: 56px;

      @media (max-width: 500px) {
        width: 180px;
        height: auto;
      }
    }

    h5 {
      font-size: 20px;
      line-height: 130%;
      text-transform: uppercase;
    }

    p {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 22px;
    }

    button {
      margin-top: 22px;
    }
  }
  .video-side {
    width: 60%;
    width: 100%;
    position: absolute;
    left: 50%;

    @media (max-width: ${variables.largeBreakPoint}) {
      position: unset;
      left: unset;
    }

    .video-wrapper {
      position: relative;
      .btn-play {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100px;
        height: 100px;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }

      margin-left: 95px;
      border-radius: 10px;
      padding: 10px;
      border: 2px solid ${variables.purpleColor};
      border-right: none;
      padding-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      iframe,
      video {
        width: 100%;
        border-radius: 10px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      @media (max-width: ${variables.largeBreakPoint}) {
        margin-left: 0px;
        padding-right: 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border: 2px solid ${variables.purpleColor};
      }
    }
  }
`;
