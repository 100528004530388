import { DoubleLayerButton } from '../components';
import QuakeSupportImage from '../assets/images/btn_support_1.png';
import USCViterbiSupportImage from '../assets/images/btn_support_2.png';
import * as S from './supporters.styles';
import classNames from 'classnames';

export default function Supporters({ className }) {
  return (
    <S.Section className={classNames('container', className)}>
      <h1>OUR SUPPORTERS</h1>
      <div className="d-flex flex-wrap button-list">
        <DoubleLayerButton className="support-btn">
          <img src={QuakeSupportImage} alt={'support-btn'} />
        </DoubleLayerButton>
        <DoubleLayerButton className="support-btn">
          <img src={USCViterbiSupportImage} alt={'support-btn'} />
        </DoubleLayerButton>
      </div>
    </S.Section>
  );
}
