import React, { useEffect } from 'react';
import { ReactComponent as Vector1 } from './assets/images/Vector1.svg';
import { ReactComponent as Vector2 } from './assets/images/Vector2.svg';
import { ReactComponent as Icon1 } from './assets/images/icon1.svg';
import { ReactComponent as Icon2 } from './assets/images/icon2.svg';
import { ReactComponent as Icon3 } from './assets/images/icon3.svg';
import Navbar from './components/Navigation/Navbar';
import About from './sections/About';
import Contact from './sections/Contact';
import Footer from './sections/Footer';
import Hero from './sections/Hero';
import Partners from './sections/Partners';
import Platform from './sections/Platform';
import Supporters from './sections/Supporters';

function App() {
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const source = queryParams.get('source');
    const resource = queryParams.get('resource');

    if (source === 'aws-blog' && resource === '5gff-whitepaper') {
      // using Java Script method to get PDF file
      fetch('OSM-5GFF-SyncStage_EDS-WhitePaper.pdf').then((response) => {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = 'OSM-5GFF-SyncStage_EDS-WhitePaper.pdf';
          alink.click();

          queryParams.delete('source');
          queryParams.delete('resource');
          window.history.pushState({}, document.title, window.location.pathname);
        });
      });
    }
  }, []);

  return (
    <div className="App">
      <Navbar />
      <Hero />
      <div className="content-layer">
        <div className="bg-wrapper">
          <div className="bg-layer">
            <Icon1 className="icon1" />
            <Icon2 className="icon2" />
            <div className="gradient"></div>
          </div>
          <div className="bg-layer2">
            <Icon3 className="icon3" />
            <div className="gradient"></div>
          </div>
        </div>
        <div className="bg-bottom-layer">
          <div className="bg-layer">
            <Vector1 width="100%" height="auto" className="vector1" />
            <Vector2 width="100%" height="auto" className="vector2" />
            <div className="gradient"></div>
          </div>
        </div>
        <About className="section-spacing" />
        <Partners className="section-spacing" />
        <Supporters className="section-spacing" />
        <Platform className="section-spacing" />
        <Contact className="section-spacing" />
        <Footer className="section-spacing" />
      </div>
    </div>
  );
}

export default App;
