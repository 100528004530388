import styled from '@emotion/styled';

export const BannerWrapper = styled.div`
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;

  .idle-content {
    position: absolute;
    inset: 0;
    z-index: 1;
  }

  .gradient-overlay {
    position: absolute;
    inset: 0;
    z-index: 2;
    background-image: linear-gradient(rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
    background-color: initial;
  }

  .banner-content {
    position: absolute;
    inset: 0;
    z-index: 3;
  }

  .btn-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
    width: 100px;
    height: 100px;
    cursor: pointer;
  }
`;
