import React, { useState } from 'react';
import {
  Alert,
  CircularProgress,
  FormHelperText,
  TextField,
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import ReCAPTCHA from 'react-google-recaptcha';
import { sendEmail } from './api';

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'white',
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      // Default transform is "translate(14px, 20px) scale(1)""
      // This lines up the label with the initial cursor position in the input
      // after changing its padding-left.
      transform: 'translate(20px, 16px) scale(1);',
    },
    '& .MuiInputLabel-root': {
      color: 'rgba(255, 255, 255, 0.4)',
    },
    '& .MuiOutlinedInput-input': {
      color: 'rgba(255, 255, 255, 0.8)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#800870',
      color: 'white',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#800870',
      color: 'white',
    },
    '& #country': {
      paddingLeft: 12,
    },
  },

  inputRoot: {
    color: 'purple',
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 16,
    },
    '& .MuiChip-root': {
      background: 'white',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#800870',
      color: 'white',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      color: 'white',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#800870',
      color: 'white',
    },
  },

  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const defaultValues = {
  name: '',
  email: '',
  subject: '',
  message: '',
  recaptchaToken: '',
  policyAccepted: false,
  signUpForNewsletter: false,
};

export default function ContactForm({ onSuccess }) {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const { control, handleSubmit, formState, watch, setValue } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  /**
   * @param {typeof defaultValues} data
   */
  const onSubmitForm = async (data) => {
    setIsLoading(true);
    try {
      await sendEmail({
        name: data.name,
        email: data.email,
        subject: data.subject,
        message: data.message,
        origin: 'opensesame.media',
        recaptchaToken: data.recaptchaToken,
        policyAccepted: data.policyAccepted,
        signUpForNewsletter: data.signUpForNewsletter,
      });
      onSuccess(true);
      setIsError(false);
    } catch (error) {
      setIsError(true);
      onSuccess(false);
    }
    setIsLoading(false);
  };

  const captchaRequiredFields = ['name', 'email', 'subject', 'message'];
  const shouldShowCaptcha = captchaRequiredFields.every((field) => formState.dirtyFields[field]);
  const isRecaptchaValid = watch('recaptchaToken');

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className="contents">
        <h1>EMAIL US</h1>
        {isError && (
          <Alert severity="error" style={{ marginBottom: 30 }}>
            Something went wrong. Please try again later.
          </Alert>
        )}
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              classes={classes}
              id="name"
              label="Name*"
              placeholder="John Doe"
              variant="outlined"
              fullWidth
              error={!!fieldState.error}
              helperText={fieldState?.error?.message}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              classes={classes}
              id="email"
              label="Email*"
              variant="outlined"
              placeholder="john@example.com"
              fullWidth
              style={{ marginTop: 20 }}
              error={!!fieldState.error}
              helperText={fieldState?.error?.message}
            />
          )}
        />
        <Controller
          name="subject"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              classes={classes}
              id="subject"
              label="Subject*"
              variant="outlined"
              fullWidth
              style={{ marginTop: 20 }}
              error={!!fieldState.error}
              helperText={fieldState?.error?.message}
            />
          )}
        />
        <Controller
          name="message"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              classes={classes}
              id="message"
              label="Message*"
              variant="outlined"
              style={{ marginTop: 20 }}
              fullWidth
              multiline
              rows={5}
              error={!!fieldState.error}
              helperText={fieldState?.error?.message}
            />
          )}
        />
        <Controller
          name="policyAccepted"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl
              required
              error={fieldState.error?.message}
              component="div"
              style={{ marginTop: 20 }}
              variant="outlined"
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      name="policyAccepted"
                      sx={{
                        '&.Mui-checked, &': {
                          color: '#800870',
                        },
                      }}
                    />
                  }
                  label={
                    <>
                      By submitting this form, I agree to the{' '}
                      <a style={{ color: 'white', textDecoration: 'underline' }} href="https://sync-stage.com/policy">
                        Privacy Policy*.
                      </a>
                    </>
                  }
                />
              </FormGroup>
              {!!fieldState.error?.message && <FormHelperText>{fieldState.error.message}</FormHelperText>}
            </FormControl>
          )}
        />
        <Controller
          name="signUpForNewsletter"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl error={fieldState.error?.message} component="div" style={{ marginTop: 20 }} variant="outlined">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      name="signUpForNewsletter"
                      sx={{
                        '&.Mui-checked, &': {
                          color: '#800870',
                        },
                      }}
                    />
                  }
                  label={<>Sign up for our newsletter and get the latest news, events, and product updates.</>}
                />
              </FormGroup>
              {!!fieldState.error?.message && <FormHelperText>{fieldState.error.message}</FormHelperText>}
            </FormControl>
          )}
        />
        {(isRecaptchaValid || shouldShowCaptcha) && (
          <div style={{ marginTop: 25 }}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={(token) => setValue('recaptchaToken', token, { shouldValidate: true })}
            />
            {formState.errors.recaptchaToken && <FormHelperText error>Please complete the captcha</FormHelperText>}
          </div>
        )}
        <button
          className="action-button mt-5"
          type="submit"
          onClick={handleSubmit(onSubmitForm)}
          disabled={isLoading}
          style={{ width: '100%' }}
        >
          {isLoading && (
            <div className={classes.loader}>
              <CircularProgress color="secondary" />
            </div>
          )}
          Submit
        </button>
      </div>
    </form>
  );
}
