import * as S from './about.styles';
import classNames from 'classnames';

export default function About({ className }) {
  return (
    <S.Section className={classNames('container', className)} id="about">
      <h1>ABOUT</h1>
      <h2>
        WE EXIST TO SHOW THE WORLD THAT WE ARE <span>MORE UNITED</span> THAN DIVIDED
      </h2>
      <p>
        From Burning Man to the Ganges River, humans for millenia have created intimate social gatherings in physical
        spaces to learn, remix and sample from each other’s creativity to increase humanity’s potential.
      </p>
      <p>
        Our mission at Open Sesame is to continue to facilitate these instant, intimate, social gatherings to build
        things together and create wealth together, wherever you are in time and space. In the real world, metaverse or
        beyond.
      </p>
      <p>
        We believe this is needed now more than ever to show - as a species - that we are more united than divided (and
        show us we are more similar in our values than we think we are). We are countering individualism, and the people
        and companies that profit from dividing us.
      </p>
    </S.Section>
  );
}
