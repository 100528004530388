import styled from '@emotion/styled';

export const Footer = styled.footer`
  padding-bottom: 100px;

  // TODO: those gradients on main page are over footer if this is not applied
  position: relative;
  z-index: 10;

  @media (max-width: 500px) {
    padding: 60px 20px;
  }

  a,
  p {
    color: white;
    margin: 0;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
  }
  .bottom-links {
    margin: auto;
    text-transform: uppercase;
    gap: 10px;
  }
  .divider {
    border: 0.1px solid #ffffff;
    margin-top: 43px;
    margin-bottom: 48px;
  }

  .logo {
    width: 240px;
    height: 56px;
  }

  .linkedin-btn {
    width: 50px;
    height: 50px;
    border-radius: 30px;
    border: none;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    .linkedin {
      width: 15px;
      height: 15px;
    }
  }
`;
