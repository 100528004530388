import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Modal } from 'react-bootstrap';
import { BannerWrapper } from './style';
import PlayBtn from '../../assets/images/btn_play.png';

function PlayableBanner({ idle, url, children }) {
  const [opened, setOpened] = useState(false);
  const playerRef = useRef(null);

  const handleOpen = () => {
    setOpened(true);
  };

  const handleClose = () => {
    setOpened(false);
  };

  return (
    <>
      <BannerWrapper>
        <div className="idle-content">{idle}</div>
        <div className="gradient-overlay" />
        <div className="banner-content">{children}</div>
        <img alt="play" className="btn-play" src={PlayBtn} onClick={handleOpen} />
      </BannerWrapper>
      <Modal onHide={handleClose} show={opened} fullscreen contentClassName="playable-modal">
        <Modal.Header closeButton />
        <Modal.Body>
          <ReactPlayer ref={playerRef} url={url} width={'100%'} height={'80vh'} controls playing />
        </Modal.Body>
      </Modal>
    </>
  );
}
export default PlayableBanner;
