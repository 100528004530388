import styled from '@emotion/styled';

export const Section = styled.section`
  h1 {
    margin-bottom: 100px;
  }
  .button-list {
    justify-content: center;
    gap: 40px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 500px) {
      justify-content: center;
    }

    .support-btn {
      margin-bottom: 70px;
    }
  }
`;
