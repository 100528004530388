import styled from '@emotion/styled';
import variables from '../styles/variables';

export const Section = styled.section`
  h1 {
    margin-bottom: 100px;
  }

  .button-list {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    gap: 20px;
    justify-content: center;

    @media (max-width: ${variables.largeBreakPoint}) {
      gap: 10px;
    }

    .collaborator-btn {
      margin-bottom: 40px;
    }
  }
`;
