import { useState } from 'react';
import ReactPlayer from 'react-player';
import SyncLogo from '../assets/images/logo_sync_stage.png';
import PlayBtn from '../assets/images/btn_play.png';
import * as S from './platform.styles';
import classNames from 'classnames';

export default function Platform({ className }) {
  const [played, setPlayed] = useState(false);

  return (
    <S.Section id="platform" className={classNames('container', className)}>
      <h1>PLATFORM</h1>
      <div className="d-flex layouts">
        <div className="contents-side">
          <a href="https://sync-stage.com" target="_blank" rel="noreferrer">
            <img className="logo" src={SyncLogo} alt="logo" />
          </a>
          <p>
            SyncStage allows application developers to create synchronous low audio latency experiences within their
            applications to enable their users to collaborate with others in ‘real time’.
          </p>
          <p>
            Developers install our patented audio pipeline via our iOS, Android and/or Unity SDKs into their
            applications and we power the end-to-end communications & collaborations of their users.
          </p>
          <p>
            <strong>
              Sing, Celebrate. Jam, Learn. Laugh. Mourn. Win. Teach. Listen. Pray. and Play Together. The possibilities
              are endless. What will you create together?
            </strong>
          </p>
          <h5>What will you create together?</h5>
          <a href={process.env.REACT_APP_REQUEST_DEMO_URL} rel="noopener noreferrer">
            <div className="btn-wrapper">
              <button className="action-button">BECOME AN EARLY ACCESS DEVELOPER</button>
            </div>
          </a>
        </div>
        <div className="video-side">
          <div className="video-wrapper">
            {!played && <img alt="play" className="btn-play" src={PlayBtn} onClick={() => setPlayed(true)} />}
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=ghHb7hmMbWc"
              width={'100%'}
              height="600px"
              controls={false}
              playing={played}
              loop
            />
          </div>
        </div>
      </div>
    </S.Section>
  );
}
