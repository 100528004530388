import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Thumbnail5GFFSmall from '../assets/images/5gff-thumbnail-small.webp';
import Thumbnail5GFF from '../assets/images/5gff-thumbnail.webp';
import AnimatedLogoSmall from '../assets/images/syncstage-logo-animated-frame.webp';
import AnimatedLogo from '../assets/videos/syncstage-logo-animated.mp4';
import LazyMedia from '../components/LazyMedia/LazyMedia';
import PlayableBanner from '../components/PlayableBanner/PlayableBanner';
import * as S from './hero.styles';

export default function Hero({ className }) {
  return (
    <S.Section className={className}>
      <Swiper
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }}
        modules={[Pagination, Autoplay]}
      >
        <SwiperSlide>
          <S.Section className="hero-section">
            <PlayableBanner
              url="https://www.youtube.com/watch?v=ghHb7hmMbWc"
              idle={<LazyMedia src={AnimatedLogo} loadingSrc={AnimatedLogoSmall} />}
            >
              <div className="container content-layer-over-hero">
                <p className="text">
                  <span className="emphasize">SYNCSTAGE</span>
                  ULTRA FAST <br />
                  AUDIO PIPELINE
                </p>
                <div className="hero-buttons">
                  <a href={process.env.REACT_APP_REQUEST_DEMO_URL} rel="noopener noreferrer" className="action-button">
                    Become an early access developer
                  </a>
                  <a
                    href="https://sync-stage.com"
                    target="_blank"
                    rel="noreferrer"
                    className=" action-button learn-button"
                  >
                    LEARN MORE
                  </a>
                </div>
              </div>
            </PlayableBanner>
          </S.Section>
        </SwiperSlide>
        <SwiperSlide>
          <S.Section className="hero-section">
            <PlayableBanner
              url="https://www.youtube.com/watch?v=Cm-S46mguC0"
              idle={<LazyMedia src={Thumbnail5GFF} loadingSrc={Thumbnail5GFFSmall} />}
            >
              <div className="container content-layer-over-hero">
                <p className="text">
                  THE WORLD'S
                  <br />
                  FIRST MUSIC
                  <br />
                  COLLABORATION
                  <br />
                  USING <span className="emphasize">SYNCSTAGE</span>
                </p>
              </div>
            </PlayableBanner>
          </S.Section>
        </SwiperSlide>
      </Swiper>
    </S.Section>
  );
}
