import React from 'react';
import Logo from '../assets/images/logo_circle.png';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '100px',
    marginBottom: 60,
  },
}));

function SuccessScreen({ title, content }) {
  const classes = useStyles();
  return (
    <div className="d-flex flex-column align-items-center">
      <img src={Logo} alt="logo" className={classes.logo} />
      <h2 className="mb-4">{title}</h2>
      <span>{content}</span>
    </div>
  );
}
export default SuccessScreen;
