import OpenLogo from '../assets/images/logo_open_sesame.png';
import LinkedinLogo from '../assets/images/logo-in.png';
import * as S from './footer.styles';
import classNames from 'classnames';

export default function Footer({ className }) {
  return (
    <S.Footer className={classNames('container', className)}>
      <div className="d-flex justify-content-space-between">
        <img className="logo" src={OpenLogo} alt="logo" />
        <a
          href="https://www.linkedin.com/company/opensesamemedia/posts/"
          className="linkedin-btn"
          target="_blank"
          rel="noreferrer"
        >
          <img className="linkedin" src={LinkedinLogo} alt="logo" />
        </a>
      </div>
      <div className="divider"></div>
      <div className="d-flex justify-content-space-between bottom-links">
        <a href="/">Copyright © 2023 Open Sesame Media, Inc.</a>
        <a href="https://sync-stage.com/policy"> Privacy Policy </a>
        <a href="https://sync-stage.com/terms"> Terms & Conditions</a>
      </div>
    </S.Footer>
  );
}
