import styled from '@emotion/styled';
import variables from '../styles/variables';

export const Section = styled.section`
  padding: 0;
  height: 100vh;

  .content-layer-over-hero {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 50px;

    @media (max-width: ${variables.largeBreakPoint}) {
      padding-bottom: 40px;
    }

    .text {
      flex-direction: column;
      font-size: 55px;
      font-weight: 700;
      display: flex;
      text-align: left !important;
      letter-spacing: -0.025em;
      line-height: 1;
      padding: 0;
      margin: 0;
      margin-bottom: 20px;

      .emphasize {
        color: #36dbfd;
      }

      @media (max-width: ${variables.largeBreakPoint}) {
        font-size: 45px;
      }

      @media (max-width: ${variables.smallBreakPoint}) {
        font-size: 35px;
      }
    }

    .hero-buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
  }
`;
