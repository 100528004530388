import { useState } from 'react';
import classNames from 'classnames';
import { ContactFormModal } from '../components';
import * as S from './contact.styles';
export default function Contact({ className }) {
  const [showContactDemoForm, setShowContactDemoForm] = useState(false);

  return (
    <S.Section className={classNames('container', className)} id="contact">
      <h1>CONTACT</h1>
      <div className="contacts">
        <div className="contact">
          <div className="title">USA</div>
          <p>Open Sesame Media, Inc. 4676 Admiralty Way, Suite 503 Marina del Rey, CA 90292</p>
        </div>
        <div className="contact">
          <div className="title">GERMANY</div>
          <p>Open Sesame GmbH Stadtwaldgürtel 42, 50931 Köln, Germany</p>
        </div>
        <div className="contact">
          <div className="title">EMAIL US</div>
          <div className="btn-wrapper" onClick={() => setShowContactDemoForm(true)}>
            <button className="action-button small">OPEN A CONTACT FORM</button>
          </div>
        </div>
      </div>
      {showContactDemoForm && <ContactFormModal onClose={() => setShowContactDemoForm(false)} />}
    </S.Section>
  );
}
