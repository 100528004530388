const variables = {
  smallBreakPoint: '576px',
  largeBreakPoint: '992px',
  redColor: '#f50107',
  purpleColor: '#800870',
  blueColor: '#1105f4',
  darkBlueColor: ' #1f094b',
  whiteColor: '#fff',
};

export default variables;
