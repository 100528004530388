import { useEffect, useState } from 'react';
import { Navbar as BsNavbar, Nav } from 'react-bootstrap';
import OpenSesameLogo from '../../assets/images/logo_open_sesame.png';
import { LINKS } from './links';
import MobileNavbar from './MobileNabvar';
import * as Styles from './styles';

export default function AppNavbar() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [scrolledY, setScrolledY] = useState(window.scrollY);

  function handleCloseMobileMenu() {
    setShowMobileMenu(false);
  }

  function handleOpenMobileMenu() {
    setShowMobileMenu(true);
  }

  function handleScroll(e) {
    setScrolledY(window.scrollY);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Styles.Navbar
      expand="lg"
      className={`navbar-dark ${scrolledY > 30 && 'shrink'}`}
      fixed="top"
      onScroll={handleScroll}
    >
      <Styles.Container>
        <BsNavbar.Brand href="/">
          <Styles.Logo src={OpenSesameLogo} alt={'open-sesame-logo'} />
        </BsNavbar.Brand>
        <BsNavbar.Toggle onClick={handleOpenMobileMenu} aria-controls="basic-navbar-nav" />
        <div className="d-none d-lg-block">
          <Nav className="ms-auto gap-2">
            {Object.values(LINKS).map(({ label, href }) => (
              <Nav.Link key={href} href={href} className="text-white">
                {label}
              </Nav.Link>
            ))}
          </Nav>
        </div>
      </Styles.Container>
      <MobileNavbar opened={showMobileMenu} onMobileMenuClose={handleCloseMobileMenu} />
    </Styles.Navbar>
  );
}
